<template>
	<a rel="noopener noreferrer" @click.prevent="openBookingEngine({ ...$props })">
		<slot />
	</a>
</template>

<script setup>
import { BookingEngineFactory } from '@becurious/mews';

const { locale } = useI18n();
const config = useRuntimeConfig();
const bookingEngine = useState('bookingEngine', () => {});

defineProps({
	language: { type: String, default: '' },
	arrival: { type: String, default: '' },
	departure: { type: String, default: '' },
	pax: { type: String, default: '' },
	packageid: { type: String, default: '' },
	promo: { type: String, default: '' },
	roomid: { type: String, default: '' },
});

useHead({
	script: [
		{
			key: 'mews-distributor',
			src: 'https://api.mews.com/distributor/distributor.min.js',
		},
	],
});

const openBookingEngine = (options) => {
	if (!bookingEngine.value) {
		return;
	}

	if (!options.language) {
		options = { ...options, language: locale.value };
	}

	bookingEngine.value.visitUrl(options);
};

onMounted(() => {
	if (!bookingEngine.value) {
		const factory = new BookingEngineFactory({
			hotelID: 'e5f87e2a-78d9-4ad1-b9f9-57e35804d30b',
			gtmID: config.public.googleTagManagerId,
			primaryColor: '#cda176',
			mode: 'widget',
		});

		bookingEngine.value = factory.createBookingEngine();
	}
});
</script>

<style lang="scss" scoped>
a {
	color: #fff;
	cursor: pointer;

	&.fixedmobile {
		display: none;
		width: 100%;
		position: fixed;
		bottom: 0;
		margin: 0;
		padding: 20px 0;
		z-index: 99;

		svg {
			margin-right: 5px;
		}
	}

	&:hover {
		text-decoration: none;
	}
}

@media (max-width: 600px) {
	a.fixedmobile {
		display: block;
	}
}
</style>
